import React, { Component } from 'react';
import Highcharts from "highcharts";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MainGraph, AVGContainer, ContainerInsideChart } from './styles';
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';
// eslint-disable-next-line no-undef
require('highcharts/highcharts-more')(Highcharts);
// eslint-disable-next-line no-undef
require('highcharts/modules/solid-gauge')(Highcharts);

class NewHighchartComponent extends Component {

  componentDidMount() {
    const { payload, id } = this.props;
    new Highcharts.chart({
      chart: {
        type: 'solidgauge',
        renderTo: id,
        height: '142px',
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
      },

      title: null,

      credits: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },

      pane: {
        startAngle: -160,
        endAngle: 160,
        background: [{
          outerRadius: '112%',
          innerRadius: '103%',
          backgroundColor: '#E9E9E9' || Highcharts.Color(Highcharts.getOptions().colors[0])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
          shape: "arc"
        }],
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: parseFloat(payload.percent_steps) > 0
        }
      },

      series: [{
        name: 'Move',
        data: [{
          color: '#005c87',
          radius: '112%',
          innerRadius: '103%',
          y: parseFloat(payload.percent_steps)
        }],
      }]
    });
  }

  render() {
    const { id, payload, t} = this.props;
    window.console.log("payload new", payload)
    return (
      <MainGraph>
        <div id={id} />
        <AVGContainer>{t("Walked")}</AVGContainer>
        <ContainerInsideChart steps={parseInt(payload && payload.steps)}>
          <div style={{height:'24px', display:'flex', justifyContent:'center', marginTop:'-15px'}}>
            <LazyImage src={ImageUrl+"/ChallengeDetailsScreen/steps-fitness-card.svg"} style={{marginTop:'-12px'}}/>
          </div>
          {payload ? parseInt(payload.steps) : null}
          {/* {commaSeperator(parseInt(WorkoutData['steps'] || 0))} */}
          <p>{t("Steps")}</p>
        </ContainerInsideChart>
      </MainGraph>
    )
  }
}

NewHighchartComponent.propTypes = {
  id: PropTypes.string,
  payload: PropTypes.object,
  t: PropTypes.func
};

export default (withTranslation()(NewHighchartComponent));
