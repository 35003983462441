import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { commaSeperator, convertMilesToKm, convertMonthFromDate } from '../../../utils/methods';
import InputGoals from '../../InputGoals';
import moment from "moment";
import {getDailyActivityOverview, getMonthlyActivityOverview} from "../../../redux/actions/wellnessDashboardActions";
import {connect} from "react-redux";
import {LoaderContainer} from "../../Content/styles";
import {ContainerV2} from "../../ContentV2/styles";
import Loading from "../../Loading";
import Waiting from "../../Waiting";
import {withTranslation} from 'react-i18next';
import { ChallengeHeader } from '../../ChallengeDashboardV2/styles';
import LazyImage from '../../common/LazyImage/LazyImage';
import { CaloriesBurned, Content, ContentWrapper, CurrentTime, DailyFitnessActivityHeader, DateRightArrow, LeftArrow, MyDailyActivityContainer, NavigationBox, StepAndDistance } from './DailyFitnessActivityNewStyle';
import NewHighChart from './newHighChart';
import { ImageUrl } from '../../../utils/constants';
const monthlyGoalsData = [
  {text: "Steps Target", key: "monthly_steps"},
  {text: "Calories burned Target", key: "monthly_calories"},
  {text: "Distance Target", key: "monthly_distance"}
];
class DailyFitnessActivity extends Component {
  constructor() {
    super();
    this.state = {
      showInputGoalsModal: false,
      modalName: '',
      date: new Date()
    };
  }
  componentDidMount() {
    const { date } = this.state;
    const{challenge, getMonthlyActivityOverview} = this.props;
    const monthStartDate = challenge && moment(date).startOf('month').format('YYYY-MM-DD');
    challenge ? getMonthlyActivityOverview(monthStartDate) : this.getActivityOverviewData(this.getStartDate(date, 'day'), this.getEndDate(date, 'day'));
  }
  showModal = (name) => {
    this.setState({
      modalName: name,
    });
  };

  hideModal = () => {
    this.setState({
      modalName: ''
    });
  };
  getStartDate = (date, value) => this.props.challenge ? moment(date).startOf(value).format('YYYY-MM-DD') : moment(date).startOf(value).format('MM-DD-YYYY');

  getEndDate = (date, value) => moment(date).endOf(value).format('MM-DD-YYYY');

  getActivityOverviewData = (startDate, endDate) => {
    this.props.fetchActivityOverview(startDate, endDate)
  };

  changeToPreviousValue = (value) => {
    const { date } = this.state;
    const {challenge, getMonthlyActivityOverview} = this.props;
    this.setState({
      date: moment(date).subtract(1, value)
    }, () => {
      challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) : this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
    });
  };

  changeToNextValue = (value) => {
    const { date } = this.state;
    const {challenge, getMonthlyActivityOverview} = this.props;
    this.setState({
      date: moment(date).add(1, value)
    }, () => {
      challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) :  this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
    });
  };
  render() {
    const { modalName, date} = this.state;
    const {SetDailyGoal, inputGoals, activityOverview, activityLoading, challenge, monthlyActivityOverview,t} = this.props;
    const disableRightArrow = moment(date).startOf(challenge ? 'month' : 'day') >= moment().startOf(challenge ? 'month' : 'day');
    // const leftArrowDisabledMonth = moment().subtract(1, 'months').startOf(challenge ? 'month' : 'day');
    // const disabledLeftArrow = moment(date).isBefore(leftArrowDisabledMonth);
    const januaryOfCurrentYear = moment().startOf('year');
    const disabledLeftArrow = moment(date).isSame(januaryOfCurrentYear, 'month');
    const setWaiting = challenge ? !monthlyActivityOverview : !activityOverview;
    if(setWaiting){
      return <Waiting />;
    }
    const monthlyGoals = challenge && monthlyActivityOverview['monthly_goals'];
    const WorkoutData = challenge && monthlyActivityOverview['workout_data'];
    const DistanceUnit = localStorage.getItem('DISTANCE');
    const lang = localStorage.getItem("lang");
    return (
      <ContainerV2 height={challenge ? 'auto' : '240px'} width={challenge ? '100%' : 'calc(62% - 15px)'}>
        <ChallengeHeader style={{color:'#005c87', marginBottom:'30px'}}>{challenge ? t('Fitness Goal') : t('Daily Fitness Activity')}</ChallengeHeader>
        <NavigationBox isDailyFitnessActivity>
          <DailyFitnessActivityHeader>
            <CurrentTime>
              <LeftArrow onClick={() => this.changeToPreviousValue(challenge ? 'month' : 'day')} disable={disabledLeftArrow}/>
              <div>
                { lang!="fr" ? <div className="Date">{ convertMonthFromDate(moment(date).startOf('Month').format('MMMM'), this.props) } {t("Month Avg.")}</div> : <div className="Date"> {t("Month Avg.")} { convertMonthFromDate(moment(date).startOf('Month').format('MMMM'), this.props) } </div>}
              </div>
              <DateRightArrow onClick={() => this.changeToNextValue(challenge ? 'month' : 'day')} disable={disableRightArrow} />
            </CurrentTime>
          </DailyFitnessActivityHeader>
          <div style={{height:'286px', width:"398px"}}>
            {activityLoading && <div style={{position:'absolute', bottom:'0px',width:"398px",height:'102px', background:'white', borderRadius:'0px 0px 6px 6px'}}></div>}
            {activityLoading ? <LoaderContainer><Loading/></LoaderContainer> :
              <ContentWrapper>
                {
                  <Content>
                    <StepAndDistance left>
                      <LazyImage src={ImageUrl+"/ChallengeDetailsScreen/calorie-fitness-card.svg"} alt="Footprint"/>
                      <div
                        className="StepsCount">
                        {/* {challenge ? WorkoutData && commaSeperator(parseInt(WorkoutData['steps'] || 0)) : activityOverview && commaSeperator(parseInt(activityOverview['steps'] || 0))} */}
                        {WorkoutData && WorkoutData.calories }
                        {" "}{t('Kcal')}
                      </div>
                      <div className="TotalSteps">{t("Calories")}</div>
                    </StepAndDistance>
                    <CaloriesBurned>
                      <NewHighChart id="graph" payload={challenge ? WorkoutData  : activityOverview }/>
                    </CaloriesBurned>
                    <StepAndDistance right>
                      <LazyImage src={ImageUrl+"/ChallengeDetailsScreen/distance-fitness-card.svg"} alt="Distance"/>
                      <div
                        className="StepsCount">{challenge ? DistanceUnit === "KILOMETER"?WorkoutData && `${convertMilesToKm(WorkoutData['distance'] || 0.0)}${'km'}` : WorkoutData && `${parseFloat(WorkoutData['distance'] || 0.00).toFixed(1)}
                        ${t('mi')}` : 
                          activityOverview && `${parseFloat(activityOverview['distance'] || 0.00).toFixed(1)} mil`}</div>
                      <div className="TotalSteps">{t("Distance")}</div>
                    </StepAndDistance>
                  </Content>
                }
                {
                  challenge ? <MyDailyActivityContainer>
                    <div>
                      {t("My Daily Goal")} 
                    </div>
                    <div>
                      {monthlyGoalsData.map((data, index) => (<div key={index}>
                        <div>
                          {data.text === 'Total Distance' ?DistanceUnit === "KILOMETER"?`${convertMilesToKm(monthlyGoals[data.key])} km` :`${monthlyGoals[data.key]} mi` : commaSeperator(parseInt(monthlyGoals[data.key]))}
                        </div>
                        <div>
                          {t(data.text)}
                        </div>
                      </div>))
                      }
                    </div>
                  </MyDailyActivityContainer> : null
                }
              </ContentWrapper>
            }
          </div>
        </NavigationBox>
        <InputGoals SetDailyGoal={SetDailyGoal} showModal={modalName === 'inputGoal'} onHide={this.hideModal} inputGoals={inputGoals} />
      </ContainerV2>
    )
  }
}

DailyFitnessActivity.propTypes = {
  SetDailyGoal: PropTypes.func,
  inputGoals: PropTypes.object,
  activityOverview: PropTypes.object,
  activityLoading: PropTypes.bool.isRequired,
  fetchActivityOverview: PropTypes.func.isRequired,
  challenge: PropTypes.number,
  getMonthlyActivityOverview: PropTypes.func,
  monthlyActivityOverview: PropTypes.object,
  t: PropTypes.func

};

const mapStateToProps = (state) => ({
  activityOverview: state.wellnessDashboard.dailyActivityOverview,
  activityLoading: state.wellnessDashboard.activityLoading,
  monthlyActivityOverview: state.wellnessDashboard.monthlyActivityOverview
});

const mapDispatchToProps = (dispatch) => ({
  fetchActivityOverview: (startDate, endDate) => dispatch(getDailyActivityOverview(startDate, endDate)),
  getMonthlyActivityOverview: (date) => dispatch(getMonthlyActivityOverview(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DailyFitnessActivity));
